
















































































































































/* eslint-disable */
import {useActions, useGetters, useState} from '@u3u/vue-hooks'
import {defineComponent, ref} from '@vue/composition-api'
import UploadRecord from '@/components/CommuneUpload.vue'
import UploadRecordHeader from '@/components/CommuneUploadHeader.vue'
import DatepickerRecord from '@/components/DatepickerRecord.vue'
import DatePanel from '@/components/DatePanel.vue'
import moment from 'moment'

export default defineComponent({
  name: 'step-eight-commune',
  components: {UploadRecord, DatepickerRecord, UploadRecordHeader},
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    // global data
    const {user, chrome} = useState(['user', 'chrome'])
    const {i18n, i18nSuivi} = useState('my', ['i18n', 'i18nSuivi'])
    const dossier = ref<any | null>()
    const date = ref('')
    const {showPanel} = useActions('sidePanel', ['showPanel'])
    const EIGHTYONE = ref('81')
    const EIGHTYTWO = ref('82')
    const EIGHTYTHREE = ref('83')
    const EIGHTYFOUR = ref('84')
    const EIGHTYFIVE = ref('85')
    const {currentLang} = useGetters(['currentLang'])

    if (
        _props.parentData.codeStatut === EIGHTYTWO.value ||
        _props.parentData.codeStatut === EIGHTYTHREE.value
    ) {
      for (let i in _props.parentData.liste) {
        if (
            _props.parentData.liste[i].codeInfo === '100_1' ||
            _props.parentData.liste[i].codeInfo === '100_2'
        ) {
          date.value = moment(_props.parentData.liste[i].valeurInfo)
              .locale(currentLang.value)
              .format('DD MMMM YYYY')
        }
      }
    }

    const getDate = date => {
      return moment(date).locale(currentLang.value).format('DD MMMM YYYY')
    }

    const processEanCptDate = (text: string, ean: string, cpt: string) => {
      let html
      if (ean === null) {
        html = text.replace('(EAN %ean)', ' ')
      } else {
        html = text.replace('%ean', ean)
      }
      html = html.replace('%numCompteur', cpt)
      html = html.replace('%date', date.value)
      return html
    }

    const showDatePanel = (e, idDossier, index, idRecord) => {
      e.preventDefault()

      showPanel({
        component: {
          template: DatePanel,
        },
        content: {
          idDossier,
          index,
          idRecord,
        },
      })
    }
    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      dossier,
      date,
      i18nSuivi,
      EIGHTYONE,
      EIGHTYTWO,
      EIGHTYTHREE,
      EIGHTYFOUR,
      EIGHTYFIVE,
      processEanCptDate,
      showDatePanel,
      getDate,
      currentLang,
    }
  },
})
